.meditation-page {
    position: absolute;

    width: 100vw;
    height: 100vh;
}

.meditation-page-container {
    position: relative;
    top: 71px;

    display: flex;
    flex-direction: column;
}

.meditation-page-title {
    margin-top: 36px;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #222B60;
}

.meditation-audio-container {
    position: relative;

    display: grid;
    grid-template-columns: 231px 231px 231px;
    grid-template-rows: repeat(2, 168px);
    gap: 18px 64px;

    margin: 35px auto 40px;
}

.meditation-audio-image {
    width: 223px;
    height: 168px;
    border: 4px solid transparent;
    border-radius: 20px;

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url(/public/assets/audio-backgrounds/audio-meditation.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}

.meditation-audio-item-selected {
    border: 4px solid #EA6852;
    border-radius: 20px;
}

.meditation-audio-item-unselected {
    opacity: 0.7;
}

.meditation-audio-image-title {
    margin: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;

    color: #FFFFFF;
}

.meditation-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 0 auto 100px;
    width: 450px;
}

@media (max-width: 1200px) {
    .meditation-page-container {
        top: 23px;
    }

    .meditation-audio-container {
        margin-bottom: 44px;

        grid-template-columns: repeat(3, 186px);
        grid-template-rows: repeat(2, 134px);
        gap: 24px 64px;
    }

    .meditation-audio-image {
        width: 186px;
        height: 134px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .meditation-page-container {
        top: 23px;
    }

    .meditation-audio-container {
        margin-bottom: 44px;

        grid-template-columns: repeat(3, 186px);
        grid-template-rows: repeat(2, 134px);
        gap: 24px 64px;
    }

    .meditation-audio-image {
        width: 186px;
        height: 134px;
    }
}

@media (min-width: 1300px) and (max-height: 800px) {
    .meditation-page-container {
        top: 23px;
    }

    .meditation-audio-container {
        margin-bottom: 44px;

        grid-template-columns: repeat(3, 186px);
        grid-template-rows: repeat(2, 134px);
        gap: 24px 64px;
    }

    .meditation-audio-image {
        width: 186px;
        height: 134px;
    }
}
