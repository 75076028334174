.disclaimer-page {
    position: absolute;

    width: 100vw;
    height: 100vh;
}

.disclaimer-page-progressbar-container {
    margin-top: 57px;
}

.disclaimer-page-title {
    margin-top: 36px;

    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #222B60;
}

.disclaimer-page-buttons-container {
    position: absolute;
    z-index: 0;
    bottom: 130px;
    left: 50%;
    transform: translateX(-50%);
}

.feeling-before-meditation-page {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100vh;
}

.disclaimer-modal-buttons-container {
    left: -20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: auto;
    width: 450px;
}


