.progressbar-container {
    position:relative;
}

.progressbar-image {
    position: relative;
    width: 569px;
    margin: 0;
}

@media screen and (max-width: 1200px) {
    .progressbar-container {
        margin-bottom: 32px;
    }
}
