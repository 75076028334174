.meditation-page-quote-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100vw;
    height: 100vh;

    z-index: 3;
}

.meditation-page-quote-background {
    position: fixed;

    opacity: 0.44;

    width: 100vw;
    height: 100vh;

    background-color: #060A19;
}

.meditation-page-quote {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 728px;
    height: 428px;
    transform: translate(-50%, -50%);
    padding: 64px 24px 24px;

    background: #FFFFFF;
    border-radius: 20px;
}

.meditation-page-quote-logo {
    margin-bottom: 50px;
}

.meditation-page-quote-text {
    margin-bottom: 26px;

    font-weight: 700;
    font-size: 30px;
    line-height: 150%;

    letter-spacing: -0.0005em;

    color: #222B60;
}

.meditation-page-quote-author {
    margin-bottom: 26px;

    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
    color: #222B60;
}

.meditation-page-quote-subtext {
    position: absolute;
    bottom: 54px;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: #838383;
}
