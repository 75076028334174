.faq-button-container {
    position: fixed;
    left: 41px;
    bottom: 67px;
}

.faq-button-icon {
    transition: all 0.5s ease;
    cursor: pointer;
}

.faq-button-icon:hover {
    transform: scale(1.1);
}
