.device-page {
    position: absolute;

    width: 100%;
    height: 100%;
}

.device-page-container {
    position: relative;
    top: 71px;

    display: flex;
    flex-direction: column;
}

.device-page-title {
    margin-top: 36px;

    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #222B60;
}

.device-page-devices-container {
    margin-top: 50px;

    display: flex;
    justify-content: center;
    gap: 94px;
}

.device-page-device-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.device-page-device-text {
    position: absolute;

    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

.device-page-device-image {
    border: 4px solid transparent;
    border-radius: 25px;
    object-fit: cover;

    width: 236px;
    height: 310px;

    cursor: pointer;
}

.device-page-device-image-selected {
    border: 4px solid #EA6852;
    border-radius: 25px;
}

.device-page-device-image-unselected {
    opacity: 0.7;
}

.device-page-device-image-locked {
    opacity: 0.7;
    cursor: auto;
}
.device-page-vr-subtitle {
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
    color: #222B60;
}

.device-page-vr-subtitle-hidden {
    visibility: hidden
}

.device-page-buttons-container {
    margin-bottom: 100px;
}

@media (max-width: 1200px) {
    .device-page-container {
        top: 43px
    }

    .device-page-devices-container {
        gap: 64px;
        margin-bottom: 20px;
    }

    .device-page-device-image {
        width: 189px;
        height: 236px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .device-page-container {
        top: 43px
    }

    .device-page-devices-container {
        gap: 64px;
        margin-bottom: 60px;
    }

    .device-page-device-image {
        width: 189px;
        height: 236px;
    }
}

@media (min-width: 1300px) and (max-height: 800px) {
    .device-page-container {
        top: 43px
    }

    .device-page-devices-container {
        gap: 64px;
        margin-bottom: 60px;
    }

    .device-page-device-image {
        width: 189px;
        height: 236px;
    }
}
