.button {
    transition: 0.5s;

    margin: 0 auto 0;
    width: 214px;
    height: 59px;

    background: transparent;
    box-shadow: 0 1px 1.41px rgba(0, 0, 0, 0.2);
    border-radius: 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;

    cursor: pointer;
}

.button-filled {
    background: #EA6852;
    border: 1px solid #EA6852;
}

.button-filled:hover {
    transform: scale(1.05);
}

.button-filled-disabled {
    border: 1px solid #EA6852;
    background: #EA6852;
    opacity: 0.4;
}

.button-filled-hidden {
    opacity: 0;
    transition: 0.5s;
}

.button-filled-disabled:hover {
    transform: scale(1);
}

.button-outlined {
    border: 1px solid #EA6852;
    color: #EA6852;
}

.button-outlined:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 1200px) {
    .button-am-ready {
        position: relative;

        bottom: 50px;
    }
}
