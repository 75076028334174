:root {
    --diagonal: 486px;
}

.base-timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    height: var(--diagonal);
    width: var(--diagonal);
}

.base-timer__label {
    position: absolute;

    width: var(--diagonal);
    height: var(--diagonal);

    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 240px;
    line-height: 125%;

    letter-spacing: -0.0005em;

    color: #FFFFFF;
}

.base-timer__circle {
    fill: none;
    stroke: none;
}

.base-timer__path-elapsed {
    stroke-width: 1px;
    stroke: white;
    stroke-dasharray: 1 4.9;
    opacity: 0.7;
}

.base-timer__path-remaining-countdown-off {
    stroke-width: 1px;

    stroke-linecap: round;

    transform: rotate(90deg);
    transform-origin: center;


    transition: 1s linear all;

    stroke: white;
}

.base-timer__path-remaining-countdown-on {
    stroke-width: 1px;

    stroke-linecap: round;

    transform: rotate(90deg);
    transform-origin: center;


    transition: 1s linear all;

    stroke: white;

    animation: stroke-dasharray-animation 20s linear forwards;
}

@keyframes stroke-dasharray-animation {
    0%   {stroke-dasharray: 283 283;}
    25% {stroke-dasharray: 0 283;}
    100% {stroke-dasharray: 0 283;}
}

.base-timer__svg {
    transform: scaleX(-1);
}

@media (max-width: 1200px) {
    :root {
        --diagonal: 400px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    :root {
        --diagonal: 400px;
    }
}
