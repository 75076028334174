.scenery-page {
    position: absolute;

    width: 100vw;
    height: 100vh;
}

.scenery-page-container {
    position: relative;
    top: 71px;

    display: flex;
    flex-direction: column;
}

.scenery-page-title {
    margin-top: 36px;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #222B60;
}

.scenery-scenes-container {
    margin-top: 50px;
    margin-bottom: 50px;

    display: flex;
    justify-content: center;
    gap: 94px;
}

.scenery-scene-image {
    border: 4px solid transparent;
    border-radius: 20px;
    width: 236px;
    height: 301px;

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.scenery-scene-image-selected {
    border: 4px solid #EA6852;
    border-radius: 20px;
}

.scenery-scene-image-unselected {
    opacity: 0.7;
}

.scenery-scene-image-forest {
    background-image: url(/public/assets/scenery-backgrounds/forest-scenery.png);
}

.scenery-scene-image-beach {
    background-image: url(/public/assets/scenery-backgrounds/beach-scenery.png);
}

.scenery-scene-image-lake {
    background-image: url(/public/assets/scenery-backgrounds/lake-scenery.png);
}

.scenery-scene-image-title {
    margin: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

.scenery-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 0 auto 100px;
    width: 450px;
}

@media screen and (max-width: 1200px) {
    .scenery-page-container {
        top: 43px
    }

    .scenery-scenes-container {
        gap: 64px;
        margin-bottom: 60px;
    }

    .scenery-scene-image {
        width: 189px;
        height: 236px;
    }
}

@media (min-width: 1300px) and (max-height: 800px) {
    .scenery-page-container {
        top: 43px
    }

    .scenery-scenes-container {
        gap: 64px;
        margin-bottom: 60px;
    }

    .scenery-scene-image {
        width: 189px;
        height: 236px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .scenery-page-container {
        top: 43px
    }

    .scenery-scenes-container {
        gap: 64px;
        margin-bottom: 60px;
    }

    .scenery-scene-image {
        width: 189px;
        height: 236px;
    }
}
