.sound-progress-container {
    position: absolute;
    top: 48px;
    left: 57px;
    right: 57px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sound-progress-title-container {
    display: flex;
    justify-content: space-between;
}

.sound-progress-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

.total-duration {
    opacity: 0.5;
}

.range {
    pointer-events: none;
    margin: 16px auto 0;
    height: 8px;
    width: 100%;
    -webkit-appearance: none;
    border-radius: 10px;
    opacity: 0.55;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: none;
}

.progress-bar-cover {
    background-color: white;
    width: 20%;
    height: 8px;
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 40px;
    z-index: 1;
    user-select: none;
    pointer-events: none;
}
