.sound-controls-hidden {
    opacity: 0;
}

.sound-controls-container {
    position: absolute;
    left: 48px;
    bottom: 108px;
    z-index: 2;
    width: 55px;
    height: 114px;

    background-image: url("/public/assets/sound-controls/sound-controls-background.png");
}

.volume-control-container-opener-button {
    position: relative;
    top: 12px;
}

.volume-control-container {
    position: absolute;
    left: 120px;
    bottom: 120px;

    width: 320px;
    height: 40px;

    background-image: url("/public/assets/sound-controls/volume-background.png");
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.volume-icon-main {
    position: absolute;
    left: 12px;
    bottom: 10px;
}

.volume-slider-container {
    position: absolute;
    left: 46px;
    bottom: 10px;
}
.volume-slider-container::before {
    content: '';
    background-color: #FFFFFF;
    width: 261px;
    height: 5px;
    display: block;
    position: absolute;
    border-radius: 8px;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 1;
}

.volume-slider {
    -webkit-appearance: none;
    background-color: white;
    height: 5px;
    width: 261px;
    border-radius: 8px;
    opacity: 0.5;
    margin: 0 auto;
}

.volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #EA6852;
}

.volume-slider-progress-bar-cover {
    background: #EA6852;
    width: 20%;
    height: 6px;
    display: block;
    position: absolute;
    border-radius: 8px;
    top: 15px;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
}

.volume-slider-thumb {
    width: 24px;
    height: 24px;
    box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
    border-radius: 43px;
    background: #EA6852;
    position: absolute;
    top: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    user-select: none;
    z-index: 2;
}

.controls-container-link {
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 45px;
    bottom: 60px;
}

.controls-container-link-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #EA6852;
    text-decoration: none;
}

.controls-container-link-text:visited {
    text-decoration: none;
}

.controls-showed {
    position: absolute;
    right: 64px;
    bottom: 64px;

    width: 22px;
    height: 22px;

    background: url("/public/assets/sound-controls/eye-on.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.controls-hidden {
    position: absolute;
    right: 64px;
    bottom: 64px;

    width: 22px;
    height: 22px;

    background: url("/public/assets/sound-controls/eye-off.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.hint-bubble-left {
    width: 347px;
    height: 43px;

    padding-left: 15px;

    line-height: 43px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;

    text-align: left;

    background: url("/public/assets/sound-controls/bubble-from-left.png");
    background-position: center;
    background-repeat: no-repeat;
}

.hint-bubble-play-stop {
    position: absolute;
    bottom: 176px;
    left: 126px;
}

.hint-bubble-volume {
    position: absolute;
    bottom: 121px;
    left: 126px;
}

.hint-bubble-interface-hide-show {
    position: absolute;
    bottom: 54px;
    right: 103px;

    width: 347px;
    height: 43px;

    padding-left: 13px;

    line-height: 43px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;

    text-align: left;

    background: url("/public/assets/sound-controls/bubble-from-right.png");
    background-position: center;
    background-repeat: no-repeat;
}

.hint-hidden {
    display: none;
}
