.countdown-page {
  position: absolute;
  z-index: 10;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
}

.tablet-timer-container {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-image: url("/public/assets/backgrounds/tabletStart.png");
  background-color: #085984;
  background-repeat: no-repeat;
  background-size: cover;
}

.countdown-page-box {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: auto;
}

.countdown-page-title {
  display: flex;
  align-items: center;
  text-align: center;

  margin-bottom: 120px;

  font-weight: 700;
  font-size: 36px;
  line-height: 125%;
  /* identical to box height, or 45px */
  color: #FFFFFF;
  letter-spacing: -0.0005em;
}

.countdown-page-title-hidden {
  display: none;
}

.countdown-page-image-glasses-box {
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;

  margin-bottom: 75px;
  width: 444px;
  height: 299px;
}

.countdown-page-image-glasses {
  position: relative;

  width: 444px;
  height: 299px;
}

.countdown-page-image-glasses-small {
  transition: 1s;

  width: 285px;
  height: 192px;
}

.scene-container {
  width: 100vw;
  height: 100vh;
}

.controls-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    /*z-index: 2;*/
}

.controls-container-link {
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 40px;
    bottom: 60px;
}

.controls-container-link-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #EA6852;
    text-decoration: none;
}

.controls-container-link-text:visited {
    text-decoration: none;
}

.button-ready-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    bottom: 140px;
}

/*.meditation-page-quote-container {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/

/*    width: 100vw;*/
/*    height: 100vh;*/

/*    z-index: 3;*/
/*}*/

/*.meditation-page-quote-background {*/
/*    position: fixed;*/

/*    opacity: 0.44;*/

/*    width: 100vw;*/
/*    height: 100vh;*/

/*    background-color: #060A19;*/
/*}*/

/*.meditation-page-quote {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/

/*    width: 728px;*/
/*    height: 428px;*/
/*    transform: translate(-50%, -50%);*/
/*    padding: 90px 24px 54px;*/

/*    background: #FFFFFF;*/
/*    border-radius: 20px;*/
/*}*/

/*.meditation-page-quote-logo {*/
/*    margin-bottom: 50px;*/
/*}*/

/*.meditation-page-quote-text {*/
/*    font-weight: 700;*/
/*    font-size: 30px;*/
/*    line-height: 150%;*/

/*    letter-spacing: -0.0005em;*/

/*    color: #EA6852;*/
/*}*/

/*.meditation-page-quote-subtext {*/
/*    position: absolute;*/
/*    bottom: 54px;*/

/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*    line-height: 150%;*/

/*    color: #838383;*/
/*}*/

@media screen and (max-width: 1200px) {
  .countdown-page-title {
    margin-bottom: 90px;
  }

  .button-ready-container  {
      bottom: 90px;
  }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .countdown-page-title {
        margin-bottom: 90px;
    }

    .button-ready-container  {
        bottom: 90px;
    }
}

@media (min-width: 1300px) and (max-height: 800px) {
    .countdown-page-title {
        margin-bottom: 90px;
    }

    .button-ready-container  {
        bottom: 90px;
    }
}
