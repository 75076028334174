.all-done-page {
    position: absolute;

    width: 100vw;
    height: 100vh;
}

.all-done-page-title-container {
    position: relative;
    top: 60px;
}

.all-done-page-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #222B60;
}

.all-done-page-feeling-meter-container {
    position: relative;
    top: 210px;
}

.all-done-page-subtitle-2 {
    position: absolute;
    top: 480px;
    left: 50%;
    transform: translateX(-50%);

    font-weight: 700;
    font-size: 20px;
    line-height: 125%;

    color: #30489C;
}

.all-done-page-buttons-container {
    position: absolute;
    z-index: 0;
    top: 838px;
    left: 50%;
    transform: translateX(-50%);
}

.feeling-before-meditation-page {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100vh;
}

.all-done-page-buttons-container {
    top: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: auto;
    width: 450px;
}
