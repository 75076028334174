.feeling-meter-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 40px;

    width: 520px;
    height: 230px;

    background: #FFFFFF;
    box-shadow: 0 1px 1.41px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.feeling-meter-title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #222B60;
}

.feeling-meter-emojis-container {
    position: absolute;
    top: 100px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 444px;
}

.feeling-meter-emoji-hidden {
    opacity: 0;
    transition: all 0.2s ease;
}

.feeling-meter-emoji-visible {
    opacity: 1;
    transition: all 0.2s ease;
}

.feeling-meter-range-container {
    position: absolute;
    top: 139px;
}

.feeling-meter-range::-webkit-slider-thumb {
    background: #EA6852;
    width: 100px;
}

.feeling-meter-numbers-container {
    position: absolute;
    top: 169px;
    width: 430px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.feeling-meter-number-1 {
    position: relative;
    left: 6px;
}

.feeling-meter-number-2 {
    position: relative;
    left: 5px;
}

.feeling-meter-number-3 {
    position: relative;
    left: 3px;
}

.feeling-meter-number-4 {
    position: relative;
    left: -1px;
}

.feeling-meter-number-5 {
    position: relative;
    left: -4px;
}

.feeling-meter-number-6 {
    position: relative;
    left: 5px;
}

.feeling-meter-number-7 {
    position: relative;
    left: 4px;
}

.feeling-meter-number-8 {
    position: relative;
    left: 4px;
}

.feeling-meter-number-9 {
    position: relative;
    left: 4px;
}

.feeling-meter-number-not-active {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;

    color: #D4D4D4;

    cursor: pointer;
}

.feeling-meter-number-active-1 {
    color: #B91C1C;
}

.feeling-meter-number-active-2 {
    color: #F1854C;
}

.feeling-meter-number-active-3 {
    color: #FFBF3C;
}

.feeling-meter-number-active-4 {
    color: #84CC16;
}

.feeling-meter-number-active-5 {
    color: #579506;
}


.feeling-meter-states {
    position: absolute;
    top: 195px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.feeling-meter-states-state {
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    /* identical to box height, or 15px */

    color: #A3A3A3;

    cursor: pointer;
}

.feeling-meter-states-state-very-low {
    position: relative;
    left: -162px;
}

.feeling-meter-states-state-meh {
    position: relative;
    left: 1px;
}

.feeling-meter-states-state-fantastic {
    position: relative;
    left: 162px;
}

/**
Range slider
 */

input[type=range].styled-slider {
    width: 436px;
    height: 6px;
    -webkit-appearance: none;
}

/*progress support*/

:root {
    --max: 4;
    --min: 0;
    --value: 0;
}

input[type=range].styled-slider.slider-progress {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 20px + var(--ratio) * (100% - 20px));
}

input[type=range].styled-slider:focus {
    outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background: #222B60;
    border: none;
    box-shadow: none;
    margin-top: calc(6px * 0.5 - 24px * 0.5);
    box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);

    cursor: pointer;
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
    height: 6px;
    border: none;
    border-radius: 0.5em;
    background: #eeeeee;
    box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #222B60, #222B60, #222B60) 0/var(--sx) 100% no-repeat, #eeeeee;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 16px;
    background: #222B60;
    border: none;
    box-shadow: none;
}

input[type=range].styled-slider::-moz-range-track {
    height: 6px;
    border: none;
    border-radius: 0.5em;
    background: #eeeeee;
    box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
    background: linear-gradient(to right, #B91C1C, #EA6852, #FFBF3C) 0/var(--sx) 100% no-repeat, #eeeeee;
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
    width: 20px;
    height: 20px;
    border-radius: 16px;
    background: #222B60;
    border: none;
    box-shadow: none;
    margin-top: 0;
    box-sizing: border-box;
}

input[type=range].styled-slider::-ms-track {
    height: 6px;
    border-radius: 0.5em;
    background: #eeeeee;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
    height: 6px;
    border-radius: 0.5em 0 0 0.5em;
    margin: 0;
    background: linear-gradient(to right, #B91C1C, #EA6852, #FFBF3C);
    border: none;
    border-right-width: 0;
}
