.faq-page {
    position: absolute;

    width: 100vw;
    height: 100vh;
}

.faq-page-container {
    display: flex;
    flex-direction: column;
}

.faq-page-title {
    margin-bottom: 32px;

    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 125%;
    text-align: center;
    color: #222B60;
}

.faq-page-text-container {
    margin: 0 auto;
    width: 800px;

    text-align: left;
}

.faq-page-list-item-title {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 125%;
    color: #222B60;
}

.faq-page-list-item-text {
    margin-bottom: 20px;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #171717;
}

.faq-page-list-link {
    font-weight: 500;
    color: #171717;
}

.faq-page-list-mail {
    color: #171717;
}
