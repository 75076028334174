.scene-container {
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.scene-container-hidden {
    opacity: 0;
}

.sound-controls-vr-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    z-index: -1 !important;
    opacity: 1;
}

sound-controls-vr-container-hidden {
    opacity: 0;
}

.sound-controls-vr-container-hidden {
    opacity: 0;
}

.button-ready-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    bottom: 140px;
}

.button-ready {
    margin: 0 auto 0;
    width: 214px;
    height: 59px;
    box-shadow: 0 1px 1.41px rgba(0, 0, 0, 0.2);
    border-radius: 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;

    cursor: pointer;
    background: #EA6852;
    border: none;

    transition: 0.5s;
}

.vr-button-hidden {
    visibility: hidden  !important;
}

.vr-viewer-page {
    position: absolute;
    z-index: 4;

    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    background-image: url("/public/assets/backgrounds/tabletStart.png");
    background-color: #085984;
    background-repeat: no-repeat;
    background-size: cover;
}

.hidden-element {
    opacity: 0;
}

.vr-viewer-page-title {
    position: relative;
    top: 140px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 120px;

    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    /* identical to box height, or 45px */
    color: #FFFFFF;
    letter-spacing: -0.0005em;
}

.vr-get-ready-page-model-glasses-box {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    width: 100vw;
    height: 100vh;

    z-index: 0;
}

.button-ready-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    bottom: 140px;
}

.canvas {
    z-index: 1 !important;
}

@media (max-width: 1200px) {
    .vr-viewer-page-title {
        top: 55px;
    }

    .button-ready-container  {
        bottom: 90px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .vr-viewer-page-title {
        top: 45px;
    }

    .button-ready-container  {
        bottom: 90px;
    }
}

@media (min-width: 1300px) and (max-height: 800px) {
    .vr-viewer-page-title {
        top: 45px;
    }

    .button-ready-container  {
        bottom: 90px;
    }
}
