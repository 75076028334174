.header {
    position: relative;
    z-index: 1;
}

.header-logo {
    position: relative;
    left: 0;
    right:0;

    width: 129px;
    margin: 25px auto 0;
    padding: 0;
}

.header-link {
    position: absolute;
    z-index: 0;

    margin-top: 45px;
    margin-left: 45px;
}

.header-link-icon {
    margin-right: 13px;
}

.header-link-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;
    color: #EA6852;
}
