.post-vr-page {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background-image: url("/public/assets/backgrounds/tabletStart.png");
    background-color: #085984;
    background-repeat: no-repeat;
    background-size: cover;
}

.post-vr-page-box {
    margin: auto;
}

.post-vr-page-logo {
    margin: 0 auto 50px;
    width: 196px;
}

.post-vr-page-title {
    margin: 0 auto 64px;

    text-align: center;
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    color: #FFFFFF;
}

.post-vr-page-subtitle {
    margin-bottom: 50px;

    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    color: #FFFFFF;
}
