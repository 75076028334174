.thank-you-page {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background-image: url("/public/assets/backgrounds/tabletStart.png");
    background-color: #085984;
    background-repeat: no-repeat;
    background-size: cover;
}

.thank-you-page-box {
    margin: auto;
}

.thank-you-page-logo {
    margin: 0 auto 100px;
    width: 196px;
}

.thank-you-page-title {
    margin: 0 auto 100px;

    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    letter-spacing: -0.0005em;
    color: #FFFFFF;
}

.thank-you-page-button-container {
    margin-bottom: 36px;
}

.thank-you-page-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

    text-decoration-line: underline;

    color: rgba(255, 255, 255, 0.65);
}
