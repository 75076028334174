* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #F0F9FF;
  text-align: center;

  font-family: Poppins, serif;
}

a {
    text-decoration: none;
}
