.final-page {
    position: absolute;

    width: 100vw;
    height: 100vh;
}

.final-page-container {
    position: relative;
    top: 35px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.final-page-title-wrapper {
    margin-bottom: 30px;
}

.final-page-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    color: #222B60;
}

.final-page-image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 36px;
}

.final-page-image {
    object-fit: cover;

    width: 249px;
    height: 244px;

    filter: drop-shadow(0px 1px 1.41px rgba(0, 0, 0, 0.2));

    cursor: pointer;
}

.final-page-subtitle-0 {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;

    color: #334155;
    margin: 0;
}

.final-page-subtitle-1 {
    margin-bottom: 12px;

    font-weight: 600;
    font-size: 18px;
    line-height: 125%;

    color: #334155;
}

.final-page-subtitle-2 {
    margin-bottom: 30px;

    font-weight: 400;
    font-size: 16px;
    line-height: 125%;

    color: #94A3B8;
}

