.disclaimer-modal {
    position: absolute;

    width: 100vw;
    height: 100vh;

    z-index: 3;
}

.modal-background {
    position: fixed;

    opacity: 0.44;

    width: 100vw;
    height: 100vh;

    background-color: #060A19;

    z-index: 2;
}

.modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    display: flex;
    flex-direction: column;

    width: 820px;
    height: 553px;

    padding: 60px 40px 36px 82px;

    background: #FFFFFF;
    box-shadow: 0 1px 1.41px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.modal-title {
    margin-bottom: 36px;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: left;
    line-height: 125%;
    text-transform: uppercase;
    color: #30489C;
}

.modal-text-container {
    height: 306px;
    overflow-x: hidden;
    margin-bottom: 30px;

    text-align: left;
}

.modal-text-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background-color: #E2E8F0;
    -webkit-overflow-scrolling: auto;
    border-radius: 100vw;
    overflow-y: scroll;
}

.modal-text-container::-webkit-scrollbar-track {
    background-color: #E2E8F0;
    border-radius: 100vw;
}

.modal-text-container::-webkit-scrollbar-thumb {
    height: 33px;

    background-color: #94A3B8;
    border-radius: 100vw;
}

.modal-text {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;

    margin-right: 36px;

    background: linear-gradient(180deg, rgba(225, 225, 225, 0.2) -8.66%, rgba(255, 255, 255, 0) 16.34%), linear-gradient(0deg, rgba(225, 225, 225, 0.2) -3.59%, rgba(255, 255, 255, 0.2) 5.01%);
}

.modal-text-strong {
    font-weight: 500;
}

.modal-text-underlined {
    text-decoration: underline;
}

.disclaimer-modal-buttons-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: -42px;
}

.disclaimer-button-disagree {
    width: 214px;
    height: 59px;
    margin-right: 20px;

    background: #FFFFFF;
    border: 1px solid #EA6852;

    box-shadow: 0 1px 1.41px rgba(0, 0, 0, 0.2);
    border-radius: 40px;

    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

    color: #EA6852;

    cursor: pointer;
    transition: 0.5s;
}

.disclaimer-button-disagree:hover {
    transform: scale(1.05);
}

.disclaimer-button-agree {
    left: 20px;

    width: 214px;
    height: 59px;

    transition: 0.5s;

    background: #EA6852;
    border: 1px solid #EA6852;
    box-shadow: 0 1px 1.41px rgba(0, 0, 0, 0.2);
    border-radius: 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;

    cursor: pointer;
}

.disclaimer-button-agree:hover {
    transform: scale(1.05);
}

